import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { BookOpen, Edit2, Filter, Trash2 } from 'react-feather';
import { Card, CardBody, Row, Col, Button, Label, Input } from 'reactstrap';
import ModalUsuarios from './modal';
import { toast } from 'react-toastify';
import SweetAlert2 from 'react-sweetalert2';
import PaginationControls from "../../../components/paginador";
import {
    Paginador,
    SavePost,
    RemovePost
} from '../../../service/blog';

const Blog = () => {

    const [swalProps, setSwalProps] = useState({});
    const [tipoModal, setTipoModal] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [openFilter, setOpenFilter] = useState(false)
    const [pagina, setPagina] = useState(1);
    const [limit, setLimit] = useState(10)
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] =  useState(0);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState([{}])
    const [filter, setFilter] = useState([
        {
            pagina: 1,
            limite: 10,
            titulo: null
        }
    ])

    const [dados, setDados] = useState({
        titulo: "",
        conteudo: "",
        midia: "",
        html: "",
    })

    const [tooltipId, setTooltipId] = useState(null);

    const showTooltip = (id) => {
        setTooltipId(id);
    };

    const hideTooltip = () => {
        setTooltipId(null);
    };

    const handleOpenModal = (tipo) => {
        setTipoModal(tipo)
        setOpenModal(true)
    }

    const getPosts = (paginaAtual = pagina, limiteAtual = limit) => {
        setLoading(true);
        Paginador({ pagina: paginaAtual, limite: limiteAtual })
              .then((result) => {
                setResult(result.data);
                setTotalItems(result.data.totalResults);
                setCurrentPage(result.data.currentPage); 
                setTotalPages(result.data.totalPages);
              })
              .catch((error) => {
                console.log(error);
                toast.error("Erro ao carregar os dados!");
              });
          };  

    useEffect(() => {
        getPosts(pagina, limit);
    }, [pagina, limit]);

    function TrashClick(id) {
        console.log("ÇA PORRRA DE ID ", id)
        setSwalProps({
            show: true,
            title: 'Deseja remover o post?',
            text: "Não será possível reverter esta ação!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, remover!',
            cancelButtonText: 'Não, cancelar!',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            reverseButtons: true,
            focusCancel: true,
            focusConfirm: false,

            preConfirm: () => {
                RemoverPost(id);
                setSwalProps({ show: false });
            }

        });
    }

    const RemoverPost = (id) => {

        RemovePost(id).then((result) => {
            toast.success("Post removido com sucesso!");
            getPosts();
        }
        ).catch((error) => {
            console.log(error);
            toast.error("Erro ao remover post!");

        }
        );
    };

    const onSubmit = (fileName) => {
        dados.midia = fileName
        const dataUser = localStorage.getItem('user')
        dados.usuario_id = JSON.parse(dataUser).id
        console.log("BODY ENVIADO PARA API", dados)
        SavePost(dados).then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success('Post salvo com sucesso!')
                getPosts()
                setOpenModal(false)
            } else {
                toast.error('Erro ao salvar post!')
                getPosts()
                console.log(response)
            }
        }
        ).catch((err) => {
            console.log(err)
            toast.error('Erro ao salvar post!')
        })
        setOpenModal(false)
    }

    const columns = [
        {
            name: 'Título',
            selector: 'titulo',
            sortable: true,
            width: '10%'
        },
        {
            name: 'Conteúdo',
            selector: 'conteudo',
            sortable: true,
            width: '70%',
            cell: row => row.conteudo.length > 100 ? row.conteudo.substring(0, 100) + '...' : row.conteudo

        },
        {
            name: 'Mídia',
            selector: 'midia',
            sortable: false,
            width: '10%',
            cell: row => <img src={row.midia} alt={row.titulo} style={{ width: '70px' }} />
        },
        {
            name: 'Ações',
            selector: 'acoes',
            sortable: true,
            right: true,
            width: '10%',
            cell: row => <>
                <div onMouseOver={() => showTooltip('editar-' + row.id)} onMouseLeave={() => hideTooltip()}
                    style={{ position: 'relative', display: 'inline-block', cursor: 'pointer', }
                    }>
                    <Edit2 style={{ cursor: "pointer" }} size={18} onClick={() => handleOpenModal("Editar", row.id)} />
                    {tooltipId === 'editar-' + row.id && (
                        <div className="tooltip-adm">Editar </div>
                    )}
                </div>
                &nbsp;&nbsp;
                <div onMouseOver={() => showTooltip('remover-' + row.id)} onMouseLeave={() => hideTooltip()}
                    style={{ position: 'relative', display: 'inline-block', cursor: 'pointer', }
                    }>
                    <Trash2 style={{ cursor: "pointer" }} onClick={() => TrashClick(row.id)} size={18}></Trash2>
                    {tooltipId === 'remover-' + row.id && (
                        <div className="tooltip-adm">Remover</div>
                    )}
                </div>
            </>
        },
    ];

    const handleAvancar = () => {
        setPagina((prevPagina) => {
          const novaPagina = prevPagina + 1;
          getPosts(novaPagina, limit);  
          return novaPagina;
        });
      };
      
      const handleRetroceder = () => {
        setPagina((prevPagina) => {
          const novaPagina = prevPagina > 1 ? prevPagina - 1 : prevPagina;
          getPosts(novaPagina, limit);
          return novaPagina;
        });
      };
      
      const handleIrParaPrimeiraPagina = () => {
        setPagina(1);
        getPosts(1, limit);
      };
      
      const handleIrParaUltimaPagina = () => {
        const ultimaPagina = Math.ceil(totalItems / limit);
        setPagina(ultimaPagina);
        getPosts(ultimaPagina, limit);
      };
      
      const handleChangeItensPorPagina = (e) => {
        const novoLimit = Number(e.target.value);
        setLimit(novoLimit);
        setPagina(1);
        getPosts(1, novoLimit);  
      };

    return (
        <>
            <ModalUsuarios
                tipoModal={tipoModal}
                openModal={openModal}
                setOpenModal={setOpenModal}
                dados={dados}
                setDados={setDados}
                onSubmit={onSubmit}
            />
            <SweetAlert2 {...swalProps} didClose={
                () => setSwalProps({ show: false })
            } />
            <br />
            <Row>
                <Card>
                    <CardBody>
                        <Row>
                            <Col sm='2'>
                                <h4>Blog</h4>
                            </Col>
                            <Col sm='10' style={{
                                justifyContent: 'flex-end',
                                display: 'flex',
                                alignItems: 'right'
                            }}>
                                <Button className='buttonSideFilter' onClick={() => setOpenFilter(!openFilter)}><Filter size={18} /></Button>&nbsp;
                                <Button size='sm' className='buttonSide' onClick={() => handleOpenModal("Criar")}><BookOpen size={18} />&nbsp; Novo Post</Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

            </Row>
            <br />
            <div
                style={{
                    display: openFilter ? 'block' : 'none',
                    transitionTimingFunction: 'ease-in-out',
                    transitionDuration: '2s',
                    transform: 'translateY(-10px)'
                }}
            >
                <Row>
                    <Card >
                        <CardBody>
                            <Row>
                                <Col sm='1'>
                                    <h5>Filtros</h5>
                                </Col>
                                <Col sm='11' >
                                    <Row>
                                        <Col sm='5'>
                                            <Label>Título</Label>
                                            <Input
                                                type='text'
                                                value={filter.titulo}
                                                onChange={(e) => setFilter({ ...filter, titulo: e.target.value })}
                                            />
                                        </Col>

                                    </Row>
                                    <br />
                                    <Row>
                                        <Col sm="12">
                                            <Button
                                                size="sm"
                                                onClick={() => getPosts()}
                                                className="buttonSide"
                                                style={{ float: "right", width: "100px" }}
                                            >
                                                Filtrar
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                </Row>
            </div>

            <Row>
                <Card>
                    <CardBody>
                        <Row>
                            <DataTable
                                columns={columns}
                                data={result.data}
                                pagination={false}
                                highlightOnHover
                                responsive
                                striped
                            />
                        </Row>
                        <PaginationControls
                        pagina={pagina}
                        totalItems={totalItems}
                        limit={limit}
                        handleIrParaPrimeiraPagina={handleIrParaPrimeiraPagina}
                        handleRetroceder={handleRetroceder}
                        handleAvancar={handleAvancar}
                        handleIrParaUltimaPagina={handleIrParaUltimaPagina}
                        handleChangeItensPorPagina={handleChangeItensPorPagina}
                        />
                    </CardBody>
                </Card>
            </Row>

        </>
    )
}

export default Blog;