/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import Stepper from '../../../components/stepper'
import { Eye, EyeOff, MinusCircle, PlusCircle, RefreshCcw, User } from 'react-feather';
import { Input, Label, Spinner } from 'reactstrap';
import { Paginador } from '../../../service/planos';
import { SaveAssinatura, Pagamento } from '../../../service/assinaturas';
import axios from 'axios';
import Contrato from './contrato';
import { toast } from 'react-toastify';
import american from '../../../assets/images/logos/american.png';
import visa from '../../../assets/images/logos/visa.png';
import mastercard from '../../../assets/images/logos/master.png';
import bgSeguro from '../../../assets/images/bg/bg-modalseguro.png';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import { S3 } from "@aws-sdk/client-s3";

const Assinatura = () => {
    const [spinner, setSpinner] = useState(false);
    const [step, setStep] = useState(0);
    const [user1, setUser1] = useState([]);
    const [user2, setUser2] = useState([]);
    const [dependentes, setDependentes] = useState([]);
    const [checado, setChecado] = useState(false);
    const [planos, setPlanos] = useState([]);
    const [planoSelecionados, setPlanoSelecionados] = useState([]);
    const [numeroAssinatura, setNumeroAssinatura] = useState(0);
    const [eye, setEye] = useState(false);
    const [modalSeguro, setModalSeguro] = useState(true);
    const [dadosCartao, setDadosCartao] = useState({
        numero: "",
        cvv: "",
        validade: "",
    });
    const [seguroSelecionado, setSeguroSelecionado] = useState({
        precoSeguro: 0,
        descricaoSeguro: "Nenhum seguro selecionado",
    });
    const [dadosUsuario, setDadosUsuario] = useState({
        confirma_senha: "",
        data_nascimento: "",
        ddd: "",
        email: "",
        confirma_email: "",
        reemail: "",
        primeiro_nome: "",
        senha: "",
        sobrenome: "",
        telefone: "",
        cpf: "",
        rg: "",
        nacionalidade: "",
        estado_civil: "",
        profissao: "",
        cep: "",
        rua: "",
        numero: 0,
        doc_frente: "",
        doc_verso: ""
    });

    const [tooltipId, setTooltipId] = useState(null);

    const generatePDF = async () => {
        const input = document.querySelector(".contrato");
    
        if (!input) {
            console.error("Div não encontrada!");
            return;
        }
    
        const canvas = await html2canvas(input, { scale: 1.2 });
        const imgData = canvas.toDataURL("image/jpeg", 0.6);
    
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 210;
        const pageHeight = 297;
        const imgHeight = (canvas.height * imgWidth) / canvas.width * 0.8;
    
        let y = 0;
    
        while (y < imgHeight) {
            pdf.addImage(imgData, "JPEG", 0, y * -1, imgWidth, imgHeight, undefined, "FAST");
            y += pageHeight;
    
            if (y < imgHeight) {
                pdf.addPage();
            }
        }
    
        // Retorna o PDF como um Blob
        return pdf.output("blob");
    };
    


    const hideTooltip = () => {
        setTooltipId(null);
    };

    const showTooltip = (id) => {
        setTooltipId(id);
    };

   
    const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
    const REGION = process.env.REACT_APP_S3_REGION;
    const ACCESS_KEY = process.env.REACT_APP_S3_KEY;
    const SECRET_KEY = process.env.REACT_APP_S3_PASSWORD;

    
    const s3 = new S3Client({
    region: REGION,
    credentials: {
        accessKeyId: ACCESS_KEY,
        secretAccessKey: SECRET_KEY,
    },
});

const uploadToS3 = async (pdfBlob, dadosUsuario) => {
    const arrayBuffer = await pdfBlob.arrayBuffer(); // Converte para ArrayBuffer
    const uint8Array = new Uint8Array(arrayBuffer); // Converte para Uint8Array

    const s3 = new S3Client({
        region: REGION,
        credentials: {
            accessKeyId: ACCESS_KEY,
            secretAccessKey: SECRET_KEY,
        },
    });

    const params = {
        Bucket: S3_BUCKET,
        Key: `contratos/documento-${dadosUsuario.cpf}.pdf`,
        Body: uint8Array,  
        ACL: "public-read",
        ContentType: "application/pdf",
    };

    try {
        const command = new PutObjectCommand(params);
        await s3.send(command);
        console.log("Upload concluído com sucesso!");
    } catch (error) {
        console.error("Erro no upload:", error);
    }
};



    const handleExportPDF = async () => {
        const pdfBlob = new Blob([await generatePDF()], { type: "application/pdf" });
        if (pdfBlob) {
            await uploadToS3(pdfBlob, dadosUsuario); // Passa o CPF do usuário
        }
    };


    const botoesPlanos = () => {
        Paginador().then((response) => {
            setPlanos(response.data.data);

        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        botoesPlanos();
    }, []);

    const bandeira = (bandeira) => {
        var primeirodigito = bandeira.substring(0, 1);
        switch (primeirodigito) {
            case '3':
                return <img src={american} ></img>;
            case '4':
                return <img src={visa} ></img>;
            case '5':
                return <img src={mastercard} ></img>;
            default:
                return null;
        }
    };

    const NomeBandeira = (cartao) => {
        var primeirodigito = cartao.substring(0, 1);
        switch (primeirodigito) {
            case '3':
                return "American Express";
            case '4':
                return "Visa";
            case '5':
                return "Mastercard";
            default:
                return null;
        }
    };


    const Pagar = () => {
        setSpinner(true);
        const body = {
            assinatura: numeroAssinatura,
            cvv: dadosCartao.cvv,
            titular: dadosUsuario.primeiro_nome + " " + dadosUsuario.sobrenome,
            numero_cartao: dadosCartao.numero,
            data_vencimento: dadosCartao.validade,
            bandeira: NomeBandeira(dadosCartao.numero),
            valor: planoSelecionados.preco != null ? (planoSelecionados.preco + seguroSelecionado.precoSeguro + (planoSelecionados.preco_crianca * user1.length) + (planoSelecionados.preco_adulto * user2.length)).toFixed(2).replace('.', ',') : '0,00'
        }

        Pagamento(body).then((response) => {
            console.log("BODE PAGAMENTO", body);
            console.log("PAGAMENTO", response);
            if (response.status === 201) {
                toast.success("Pagamento realizado com sucesso!");
                setSpinner(false);
                setStep(5);
                window.scrollTo(0, 0);
            } else {
                toast.error(response.response.data.msg);
                setSpinner(false);
            }
        }).catch((error) => {
            console.log("PAGAMENTO", error);
        });
    };

    const handleInputChange = (event) => {
        const inputValue = event;
        const digitsOnly = inputValue.replace(/\D/g, ''); // Remove caracteres não numéricos
        const formatted = digitsOnly.slice(0, 16); // Limita a 16 dígitos
        const formattedWithSpaces = formatted.replace(/(\d{4})(?=\d)/g, '$1 '); // Adiciona espaço a cada 4 dígitos
        // Adiciona espaço a cada 4 dígitos

        setDadosCartao({ ...dadosCartao, numero: formattedWithSpaces });
    };

    const handleInputChangeCVV = (event) => {
        const inputValue = event;
        const digitsOnly = inputValue.replace(/\D/g, ''); // Remove caracteres não numéricos
        const formatted = digitsOnly.slice(0, 3); // Limita a 3 dígitos

        setDadosCartao({ ...dadosCartao, cvv: formatted });
    };

    const handleInputValidateChange = (event) => {
        const inputValue = event;
        const digitsOnly = inputValue.replace(/\D/g, ''); // Remove caracteres não numéricos
        const formatted = digitsOnly.slice(0, 4); // Limita a 4 caracteres

        let formattedWithSlash = '';
        if (formatted.length > 2) {
            formattedWithSlash = `${formatted.slice(0, 2)}/${formatted.slice(2)}`;
        } else {
            formattedWithSlash = formatted;
        }

        setDadosCartao({ ...dadosCartao, validade: formattedWithSlash });
    };

    const setSeguro = (seguro, valor) => {
        setSeguroSelecionado({ precoSeguro: valor, descricaoSeguro: seguro });
        setModalSeguro(false);
    };

    const submitCadastros = () => {
        setSpinner(true);
        var body = {};
        body.usuario = dadosUsuario;
        body.dependentes = dependentes;
        body.plano_id = planoSelecionados.id;
        body.preco_final = parseFloat((planoSelecionados.preco + seguroSelecionado.precoSeguro + (planoSelecionados.preco_crianca * user1.length) + (planoSelecionados.preco_adulto * user2.length)).toFixed(2));
        body.preco_seguro = seguroSelecionado.precoSeguro;
        body.descricao_seguro = seguroSelecionado.descricaoSeguro;
        console.log("BODY", body);

        SaveAssinatura(body).then((response) => {
            console.log("perereca OK", response);
            if (response.status === 201) {
                setSpinner(false);
                setNumeroAssinatura(response.data.data.id);
                window.scrollTo(0, 0);
                setStep(3);
            } else {
                toast.error(response.response.data.msg);
                setSpinner(false);
            }
        }).catch((error) => {
            console.log("perereca ERRO", error);
        });

        //retornar topo da pagina
        window.scrollTo(0, 0);
    };

    const getCEP = (cep) => {
        if (cep.length >= 8) {
            axios.get(`https://viacep.com.br/ws/${cep}/json/`)
                .then((response) => {
                    setDadosUsuario({ ...dadosUsuario, rua: response.data.logradouro, bairro: response.data.bairro, cidade: response.data.localidade, estado: response.data.uf, cep: cep });

                }).catch((error) => {
                    console.log(error);
                });
        } else {
            setDadosUsuario({ ...dadosUsuario, cep: cep });
        }
    };

    const mascaraRG = (rg) => {
        if (rg.length <= 12) {
            if (rg.length === 2 || rg.length === 6) {
                setDadosUsuario({ ...dadosUsuario, rg: rg + "." });
            } else if (rg.length === 10) {
                setDadosUsuario({ ...dadosUsuario, rg: rg + "-" });
            } else {
                setDadosUsuario({ ...dadosUsuario, rg: rg });
            }
        }
    };

    const DependentesList = () => {
        return (
            <>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Tipo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dependentes.map((item, index) => (
                            <>
                                <tr>
                                    <td>{item.primeiro_nome} {item.sobrenome}</td>
                                    <td>{item.tipo_dependente}</td>
                                </tr>
                            </>
                        ))}
                    </tbody>
                </table>

            </>
        );
    };

    const mascaraCPF = (cpf) => {
        if (cpf.length <= 14) {
            if (cpf.length === 3 || cpf.length === 7) {
                setDadosUsuario({ ...dadosUsuario, cpf: cpf + "." });
            } else if (cpf.length === 11) {
                setDadosUsuario({ ...dadosUsuario, cpf: cpf + "-" });
            } else {
                setDadosUsuario({ ...dadosUsuario, cpf: cpf });
            }
        }
    };

    const FileToBase64 = (e, tipo) => {
        const reader = new FileReader();
        reader.readAsDataURL(e);
        reader.onload = () => {

            if (tipo === 'frente') {
                setDadosUsuario({ ...dadosUsuario, doc_frente: reader.result });
            } else {
                setDadosUsuario({ ...dadosUsuario, doc_verso: reader.result });
            }

        };
        reader.onerror = (error) => {
            console.log('Error: ', error);
        };
    };

    const FileToBase64Dependentes = (e, tipo, index) => {
        const reader = new FileReader();
        reader.readAsDataURL(e);
        reader.onload = () => {
            if (tipo === 'frente') {
                let newArray = [...dependentes];
                newArray[index].doc_frente = reader.result;
                setDependentes(newArray);

            } else {
                let newArray = [...dependentes];
                newArray[index].doc_verso = reader.result;
                setDependentes(newArray);
            }

        };
        reader.onerror = (error) => {
            console.log('Error: ', error);
        };
    };

    return (
        <>
            <Modal className='modalSeguro' show={step === 1 && modalSeguro === true ? true : false} size="lg" >
                <div className='modalSeguroContainer'>
                    <div className='modalSeguroFoto' style={{ backgroundImage: `url(${bgSeguro})` }}>
                    </div>
                    <div className='modalSeguroText'>
                        <h1 style={{
                            color: '#1A4066', fontFamily: 'Poppins', fontWeight: 'bold', fontSize: '1.8rem'
                        }}>Seguro de Vida</h1>
                        <span style={{
                            color: '#1A4066', fontFamily: 'Poppins', fontWeight: 'bold', fontSize: '0.8rem', marginTop: '-10px'
                        }}>(Acidental)</span>
                        <p />
                        <p style={{
                            color: '#969696', fontFamily: 'Poppins', fontSize: '0.7rem', textAlign: 'center'
                        }}>
                            Ao investir em um seguro de vida, você proporciona tranquilidade financeira para sua família em caso de falecimento. Isso inclui cobertura para despesas como funeral, pagamento de dívidas e até mesmo educação dos filhos.
                        </p>
                        <Button className='buttonSeguro' size='sm' onClick={() => setSeguro('Seguro de vida AP R$ 10.000,00 + Assistência Funeral', 10)}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <span>
                                    Seguro de vida AP <span style={{
                                        fontFamily: 'Poppins-Bold',
                                        fontWeight: 'bold'
                                    }}>R$ 10.000,00</span> + Assistência Funeral
                                </span>
                                <span style={{
                                    fontFamily: 'Poppins-Bold',
                                    fontWeight: 'bold'
                                }}>R$ 10,00
                                </span>
                            </div>
                        </Button>
                        <Button className='buttonSeguro' size='sm' onClick={() => setSeguro('Seguro de vida AP R$ 20.000,00 + Assistência Funeral', 20)}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <span>
                                    Seguro de vida AP <span style={{
                                        fontFamily: 'Poppins-Bold',
                                        fontWeight: 'bold'
                                    }}>R$ 50.000,00</span> + Assistência Funeral
                                </span>
                                <span style={{
                                    fontFamily: 'Poppins-Bold',
                                    fontWeight: 'bold'
                                }}>R$ 20,00
                                </span>
                            </div>
                        </Button>
                        <Button className='buttonSeguro' size='sm' onClick={() => setSeguro('Seguro de vida AP R$ 100.000,00 + Assistência Funeral', 30)}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <span>
                                    Seguro de vida AP <span style={{
                                        fontFamily: 'Poppins-Bold',
                                        fontWeight: 'bold'
                                    }}>R$ 100.000,00</span> + Assistência Funeral
                                </span>
                                <span style={{
                                    fontFamily: 'Poppins-Bold',
                                    fontWeight: 'bold'
                                }}>R$ 30,00
                                </span>
                            </div>
                        </Button>
                        <Button className='buttonSeguro' size='sm' onClick={() => setSeguro('Seguro de vida AP R$ 200.000,00 + Assistência Funeral', 50)}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <span>
                                    Seguro de vida AP <span style={{
                                        fontFamily: 'Poppins-Bold',
                                        fontWeight: 'bold'
                                    }}>R$ 200.000,00</span> + Assistência Funeral
                                </span>
                                <span style={{
                                    fontFamily: 'Poppins-Bold',
                                    fontWeight: 'bold'
                                }}>R$ 50,00
                                </span>
                            </div>
                        </Button>
                        <Button className='buttonSeguro' size='sm' onClick={() => setSeguro('Sem seguro contratado', 0)}>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <span>
                                    Não quero contratar seguro.
                                </span>
                            </div>
                        </Button>
                    </div>
                    <Button size='sm' style={{ position: 'absolute', top: '10px', right: '10px', color: '#1A4066', backgroundColor: '#fff', border: 'solid 1px #1A4066' }} onClick={() => setModalSeguro(false)}>X</Button>
                </div>
            </Modal>
            <Row>
                <Col lg="2">
                </Col>
                <Col lg="8">
                    <Stepper step={step} setStep={setStep}></Stepper>
                </Col>
                <Col lg="2">
                </Col>
            </Row>
            <Row>
                <div className='step1' style={{ display: step === 0 ? "flow-root" : "none", marginTop: '20px', marginBottom: '55vh' }}>
                    <h4 style={{ textAlign: 'center' }}>Escolha o Plano:</h4>
                    <br />
                    <Row>
                        <Col lg="3">
                        </Col>
                        <Col lg="6">
                            <div className='step1bt' >
                                {planos && planos.map((plano) => (
                                    <Button className='btstep1' style={{
                                        width: '47%',
                                        margin: '5px',
                                    }} onClick={() => {
                                        setStep(1)
                                        window.scrollTo(0, 0);
                                        setPlanoSelecionados(plano)
                                    }
                                    }>{plano.descricao}</Button>
                                ))}
                            </div>
                        </Col>
                        <Col lg="3">
                        </Col>
                    </Row>
                    <br />

                </div>
                <div className='step2' style={{ display: step === 1 ? "flow-root" : "none" }}>
                    <Row>
                        <Col lg="3">
                        </Col>
                        <Col lg="6">
                            <h4 style={{ textAlign: 'center', color: '#000' }}>Adicionais ao Plano:</h4>
                            <br />
                            <Row>
                                <Col lg="6">
                                    <Card>
                                        <Card.Body style={{ backgroundColor: '#78c0db', color: '#fff', border: 'none', height: "13rem" }}>
                                            <h5 style={{ textAlign: 'center', color: '#000', marginTop: "1.5rem" }}>{planoSelecionados.descricao}</h5>
                                            <div style={{ textAlign: 'center', color: '#000', marginTop: '-5px', fontSize: '0.8rem' }}>
                                                <span>{seguroSelecionado.descricaoSeguro} {<RefreshCcw
                                                    id={'trocar-seguro-' + planoSelecionados.id}
                                                    onMouseEnter={() => showTooltip('trocar-seguro-' + planoSelecionados.id)}
                                                    onMouseLeave={hideTooltip}
                                                    onClick={() => setModalSeguro(true)}
                                                    style={{ cursor: 'pointer' }} size={10} />}
                                                    {tooltipId === 'trocar-seguro-' + planoSelecionados.id && (
                                                        <div className="tooltip-seguro">
                                                            Alterar ou Remover Seguro
                                                        </div>
                                                    )}
                                                </span>
                                            </div>
                                            <div style={{ textAlign: 'center', color: '#000', marginTop: '-5px' }}>
                                                <span style={{ fontSize: '1.5rem', position: 'relative', bottom: '1.5rem', fontWeight: 'bold' }}>R$</span>
                                                <span style={{ fontSize: '3rem', fontWeight: 'bold', textAlign: 'center' }}> {planoSelecionados.preco != null ? planoSelecionados.preco.toFixed(2).replace('.', ',') : '0,00'}</span>
                                                <span style={{ fontSize: '1.5rem', textAlign: 'center', fontWeight: 'bold' }}> /mês</span>
                                            </div>
                                            <div style={{ textAlign: 'right', marginRight: '10px', marginTop: '-5px', color: '#000' }}>
                                                titular
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="6">
                                    <Row>
                                        <Col lg="12" style={{ textAlign: 'center' }}>
                                            <span style={{ fontWeight: "bold" }} >Dependentes Crianças ( {user1.length} )</span><br />
                                            <span style={{ fontWeight: "bold", fontSize: "13px" }} >de 0 a 14 anos. </span>
                                        </Col>
                                    </Row>
                                    <p />
                                    <Row>
                                        <Col lg="12" style={{ textAlign: 'center' }}>
                                            <div>
                                                <MinusCircle size={30} style={{ marginRight: '10px' }} onClick={() => user1.length > 0 ? (
                                                    setUser1(user1.slice(0, -1)),
                                                    dependentes.pop()
                                                ) : null}></MinusCircle>
                                                {user1.map((item, index) => (
                                                    <>
                                                        <User size={user1.length < 7 ? 30 : 12} style={{ marginRight: '10px' }}></User>
                                                    </>
                                                ))}

                                                <PlusCircle size={30} style={{ marginRight: '10px' }} onClick={() => user1.length < 10 ? (
                                                    setUser1([...user1, "x"]),
                                                    setDependentes([...dependentes, {
                                                        primeiro_nome: "",
                                                        sobrenome: "",
                                                        ddd: 0,
                                                        telefone: "",
                                                        email: "",
                                                        doc_frente: "",
                                                        doc_verso: "",
                                                        tipo_dependente: "infantil"
                                                    }])
                                                ) : null}></PlusCircle>
                                            </div>
                                        </Col>
                                    </Row>
                                    <p />
                                    <Row>
                                        <Col lg="12" style={{ textAlign: 'center' }}>
                                            <span style={{ fontWeight: "bold" }} >Dependentes Adultos ( {user2.length} )</span><br />
                                            <span style={{ fontWeight: "bold", fontSize: "13px" }} >acima de 14 anos. </span>
                                        </Col>
                                    </Row>
                                    <p />
                                    <Row>
                                        <Col lg="12" style={{ textAlign: 'center' }}>
                                            <div>
                                                <MinusCircle size={30} style={{ marginRight: '10px' }} onClick={() => user2.length > 0 ? (
                                                    setUser2(user2.slice(0, -1)),
                                                    dependentes.pop()
                                                ) : null}></MinusCircle>
                                                {user2.map((item, index) => (
                                                    <>
                                                        <User size={user2.length < 7 ? 30 : 12} style={{ marginRight: '10px' }}></User>
                                                    </>
                                                ))}

                                                <PlusCircle size={30} style={{ marginRight: '10px' }} onClick={() => user2.length < 10 ? (
                                                    setUser2([...user2, "x"]),
                                                    setDependentes([...dependentes, {
                                                        primeiro_nome: "",
                                                        sobrenome: "",
                                                        ddd: 0,
                                                        telefone: "",
                                                        email: "",
                                                        doc_frente: "",
                                                        doc_verso: "",
                                                        tipo: "adulto"
                                                    }])
                                                ) : null}></PlusCircle>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <p />
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <Card.Body style={{ backgroundColor: '#0462C5', color: '#fff', border: 'none' }}>
                                            <Col lg="12">
                                                <Button className='btstep1' onClick={() => {
                                                    setStep(2)
                                                    window.scrollTo(0, 0)
                                                }}>ESCOLHER ESTE PLANO</Button>
                                                {/* <Button className='btstep1' onClick={() => setStep(5)}>ESCOLHER ESTE PLANO</Button> */}
                                                <div style={{ textAlign: 'center' }}>
                                                    <span style={{ fontSize: '1.5rem', position: 'relative', bottom: '1.5rem', fontWeight: 'bold' }}>R$</span>
                                                    <span style={{ fontSize: '4rem', fontWeight: 'bold', textAlign: 'center' }}> {planoSelecionados.preco != null ? (planoSelecionados.preco + seguroSelecionado.precoSeguro + (planoSelecionados.preco_crianca * user1.length) + (planoSelecionados.preco_adulto * user2.length)).toFixed(2).replace('.', ',') : '0,00'}</span>
                                                    <span style={{ fontSize: '1.5rem', textAlign: 'center', fontWeight: 'bold' }}> /mês</span>
                                                </div>
                                            </Col>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <br />
                        </Col>
                        <Col lg="3">
                        </Col>
                    </Row>
                </div>
                <div className='step3' style={{ display: step === 2 ? "flow-root" : "none" }}>
                    <Row>
                        <Col lg="3">
                        </Col>
                        <Col lg="6">
                            <h4 style={{ textAlign: 'center', color: '#000' }}>Cadastro de Titular:</h4>
                            <br />
                            <Row>
                                <Col lg="12">
                                    <Row>
                                        <Col lg="6" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Nome do Titular</Label>

                                            <Input
                                                type="text"
                                                name="nome"
                                                id="nome"
                                                value={dadosUsuario.primeiro_nome}
                                                onChange={e => setDadosUsuario({ ...dadosUsuario, primeiro_nome: e.target.value })}
                                            />
                                        </Col>
                                        <Col lg="6" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Sobrenome</Label>

                                            <Input
                                                type="text"
                                                name="sobrenome"
                                                id="sobrenome"
                                                value={dadosUsuario.sobrenome}
                                                onChange={e => setDadosUsuario({ ...dadosUsuario, sobrenome: e.target.value })}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <br />
                                        <Col lg="6" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >RG</Label>
                                            <Input
                                                type="text"
                                                name="rg"
                                                id="rg"
                                                value={dadosUsuario.rg}
                                                onChange={e => setDadosUsuario({ ...dadosUsuario, rg: e.target.value })}
                                            />
                                        </Col>

                                        <Col lg="6" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >CPF</Label>
                                            <Input
                                                type="text"
                                                name="cpf"
                                                id="cpf"
                                                value={dadosUsuario.cpf}
                                                onChange={e => mascaraCPF(e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>

                                        <br />
                                        <Col lg="6" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Data de Nascimento</Label>

                                            <Input
                                                type="date"
                                                name="nascimento"
                                                id="nascimento"
                                                value={dadosUsuario.data_nascimento}
                                                onChange={e => setDadosUsuario({ ...dadosUsuario, data_nascimento: e.target.value })}
                                            />
                                        </Col>
                                        <Col lg="6" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >CEP</Label>

                                            <Input
                                                type="text"
                                                name="cep"
                                                id="cep"
                                                value={dadosUsuario.cep}
                                                onChange={e => getCEP(e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Endereço</Label>
                                            <Input
                                                type="text"
                                                name="rua"
                                                id="rua"
                                                value={dadosUsuario.rua}
                                            />
                                        </Col>
                                        <Col lg="6" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Bairro</Label>
                                            <Input
                                                type="text"
                                                name="bairro"
                                                id="bairro"
                                                value={dadosUsuario.bairro}
                                                onChange={e => setDadosUsuario({ ...dadosUsuario, bairro: e.target.value })}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col lg="4" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Número</Label>

                                            <Input
                                                type="text"
                                                name="numero"
                                                id="numero"
                                                value={dadosUsuario.numero}
                                                onChange={e => setDadosUsuario({ ...dadosUsuario, numero: e.target.value })}
                                            />
                                        </Col>
                                        <Col lg="8" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Complemento</Label>

                                            <Input
                                                type="text"
                                                name="titular"
                                                id="titular"
                                                value={dadosUsuario.complemento}
                                                onChange={e => setDadosUsuario({ ...dadosUsuario, complemento: e.target.value })}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="9" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Cidade</Label>
                                            <Input
                                                type="text"
                                                name="cidade"
                                                id="cidade"
                                                value={dadosUsuario.cidade}
                                                onChange={e => setDadosUsuario({ ...dadosUsuario, cidade: e.target.value })}
                                            />
                                        </Col>
                                        <Col lg="3" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Estado</Label>
                                            <Input
                                                type="text"
                                                name="estado"
                                                id="estado"
                                                value={dadosUsuario.estado}
                                                onChange={e => setDadosUsuario({ ...dadosUsuario, estado: e.target.value })}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="4" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >DDD</Label>
                                            <Input
                                                type="number"
                                                name="ddd"
                                                id="ddd"
                                                value={dadosUsuario.ddd}
                                                onChange={e => setDadosUsuario({ ...dadosUsuario, ddd: e.target.value })}
                                            />
                                        </Col>
                                        <Col lg="8" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Telefone</Label>
                                            <Input
                                                type="text"
                                                name="telefone"
                                                id="telefone"
                                                value={dadosUsuario.telefone}
                                                onChange={e => setDadosUsuario({ ...dadosUsuario, telefone: e.target.value })}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >E-mail</Label>
                                            <Input
                                                type="email"
                                                name="email"
                                                id="email"
                                                value={dadosUsuario.email}
                                                onChange={e => setDadosUsuario({ ...dadosUsuario, email: e.target.value })}
                                            />
                                        </Col>
                                        <Col lg="6" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Confirme E-mail</Label>
                                            <Input
                                                type="text"
                                                name="re-email"
                                                id="re-email"
                                                value={dadosUsuario.confirma_email}
                                                onChange={e => setDadosUsuario({ ...dadosUsuario, confirma_email: e.target.value })}
                                                style={{ border: dadosUsuario.email === dadosUsuario.confirma_email ? '1px solid green' : '1px solid red' }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="5" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Senha</Label>
                                            <Input
                                                type={eye ? "text" : "password"}
                                                name="senha"
                                                id="titular"
                                                value={dadosUsuario.senha}
                                                onChange={e => setDadosUsuario({ ...dadosUsuario, senha: e.target.value })}
                                                valid={dadosUsuario.senha.length >= 6}
                                            />
                                        </Col>
                                        <Col lg="1">
                                            <div style={{ marginTop: '35px' }}>
                                                {eye ? (
                                                    <Eye size={17} onClick={() => setEye(false)}></Eye>
                                                ) : (
                                                    <EyeOff size={17} onClick={() => setEye(true)}></EyeOff>
                                                )}
                                            </div>
                                        </Col>
                                        <Col lg="6" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Confirmar Senha</Label>
                                            <Input
                                                type={eye ? "text" : "password"}
                                                name="resenha"
                                                id="resenha"
                                                value={dadosUsuario.confirma_senha}
                                                onChange={e => setDadosUsuario({ ...dadosUsuario, confirma_senha: e.target.value })}
                                                style={{ border: dadosUsuario.senha === dadosUsuario.confirma_senha ? '1px solid green' : '1px solid red' }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{
                                        fontSize: '11px', padding: '20px', marginLeft: '-20px'
                                    }}>

                                        <span style={{ color: dadosUsuario.senha.length < 8 ? 'black' : 'green' }}>A senha deve conter no mínimo 8 caracteres.</span>
                                        <span style={{ color: !/\d/.test(dadosUsuario.senha) ? 'black' : 'green' }}>A senha deve conter no mínimo 1 número.</span>
                                        <span style={{ color: !/[A-Z]/.test(dadosUsuario.senha) ? 'black' : 'green' }}>A senha deve conter no mínimo 1 letra maiúscula.</span>
                                        <span style={{ color: !/[a-z]/.test(dadosUsuario.senha) ? 'black' : 'green' }}>A senha deve conter no mínimo 1 letra minúscula.</span>
                                        <span style={{ color: !/[!@#$%*()_+^&{}}:;?.]/.test(dadosUsuario.senha) ? 'black' : 'green' }}>A senha deve conter no mínimo 1 caractere especial.</span>

                                    </Row>
                                    <Row>
                                        <Col lg="3" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Nacionalidade</Label>
                                            <Input
                                                type="text"
                                                name="nationalidade"
                                                id="nationalidade"
                                                value={dadosUsuario.nacionalidade}
                                                onChange={e => setDadosUsuario({ ...dadosUsuario, nacionalidade: e.target.value })}
                                            />
                                        </Col>
                                        <Col lg="3" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Estado Civil</Label>
                                            <Input
                                                type="text"
                                                name="estadocivil"
                                                id="estadocivil"
                                                value={dadosUsuario.estado_civil}
                                                onChange={e => setDadosUsuario({ ...dadosUsuario, estado_civil: e.target.value })}
                                            />
                                        </Col>
                                        <Col lg="6" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Profissão</Label>
                                            <Input
                                                type="text"
                                                name="profissao"
                                                id="profissao"
                                                value={dadosUsuario.profissao}
                                                onChange={e => setDadosUsuario({ ...dadosUsuario, profissao: e.target.value })}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Frente Documento</Label>
                                            <div style={{ display: dadosUsuario.doc_frente !== "" ? "block" : "none", width: '100%', height: '200px', border: '1px solid #dfdfdf', borderRadius: '5px', backgroundImage: `url(${dadosUsuario.doc_frente})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                            </div>
                                            <br />
                                            <Input
                                                type="file"
                                                name="fretedocumento"
                                                id="fretedocumento"
                                                onChange={e => FileToBase64(e.target.files[0], 'frente')}
                                                accept="image/*"
                                            />
                                        </Col>
                                        <Col lg="6" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Trás do Documento</Label>
                                            <div style={{ display: dadosUsuario.doc_verso !== "" ? "block" : "none", width: '100%', height: '200px', border: '1px solid #dfdfdf', borderRadius: '5px', backgroundImage: `url(${dadosUsuario.doc_verso})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                            </div>
                                            <br />
                                            <Input
                                                type="file"
                                                accept="image/*"
                                                name="trasdocumento"
                                                id="trasdocumento"
                                                onChange={e => FileToBase64(e.target.files[0], "fundo")}
                                            />
                                        </Col>

                                    </Row>
                                </Col>

                            </Row>
                        </Col>
                        <Col lg="3">
                        </Col>
                    </Row>
                    <br />
                    <hr />
                    <br />
                    <h4 style={{ textAlign: 'center', color: '#000', display: dependentes.length > 0 ? 'block' : 'none' }}>Cadastro de Dependentes:</h4>
                    {dependentes.map((item, index) => (
                        <>
                            <br />
                            <Row>
                                <Col lg="3"></Col>
                                <Col lg="6">
                                    <Row>
                                        <Col lg="6" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Nome do Dependente</Label>
                                            <Input
                                                type="text"
                                                name="dependente"
                                                id="dependente"
                                                value={item.primeiro_nome}
                                                onChange={e => {
                                                    let newArray = [...dependentes];
                                                    newArray[index].primeiro_nome = e.target.value;
                                                    setDependentes(newArray);
                                                }
                                                }
                                            />
                                        </Col>
                                        <Col lg="6" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Sobrenome</Label>
                                            <Input
                                                type="text"
                                                name="titular"
                                                id="titular"
                                                value={item.sobrenome}
                                                onChange={e => {
                                                    let newArray = [...dependentes];
                                                    newArray[index].sobrenome = e.target.value;
                                                    setDependentes(newArray);
                                                }
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Data de Nascimento</Label>
                                            <Input
                                                type="date"
                                                name="titular"
                                                id="titular"
                                                value={item.data_nascimento}
                                                onChange={e => {
                                                    let newArray = [...dependentes];
                                                    newArray[index].data_nascimento = e.target.value;
                                                    setDependentes(newArray);
                                                }
                                                }
                                            />
                                        </Col>
                                        <br />
                                        <Col lg="6" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Parentesco</Label>
                                            <Input
                                                type="text"
                                                name="parentesco"
                                                id="parentesco"
                                                value={item.parentesco}
                                                onChange={e => {
                                                    let newArray = [...dependentes];
                                                    newArray[index].parentesco = e.target.value;
                                                    setDependentes(newArray);
                                                }
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >CPF</Label>
                                            <Input
                                                type="text"
                                                name="titular"
                                                id="titular"
                                                value={item.cpf}
                                                onChange={e => {
                                                    let newArray = [...dependentes];
                                                    newArray[index].cpf = e.target.value;
                                                    setDependentes(newArray);
                                                }
                                                }
                                            />
                                        </Col>
                                        <Col lg="6" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >RG</Label>
                                            <Input
                                                type="text"
                                                name="titular"
                                                id="titular"
                                                value={item.rg}
                                                onChange={e => {
                                                    let newArray = [...dependentes];
                                                    newArray[index].rg = e.target.value;
                                                    setDependentes(newArray);
                                                }
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Frente Documento</Label>
                                            <div style={{ display: dependentes[index].doc_frente !== "" ? "block" : "none", width: '100%', height: '200px', border: '1px solid #dfdfdf', borderRadius: '5px', backgroundImage: `url(${dependentes[index].doc_frente})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                            </div>
                                            <br />
                                            <Input
                                                key={index}
                                                type="file"
                                                accept="image/*"
                                                name={`frente${index}`}
                                                id={`frente${index}`}
                                                onChange={e => FileToBase64Dependentes(e.target.files[0], 'frente', index)}
                                            />
                                        </Col>
                                        <Col lg="6" style={{ textAlign: 'left' }}>
                                            <Label style={{ fontWeight: "bold", fontSize: "11px" }} >Trás do Documento</Label>
                                            <div style={{ display: dependentes[index].doc_verso !== "" ? "block" : "none", width: '100%', height: '200px', border: '1px solid #dfdfdf', borderRadius: '5px', backgroundImage: `url(${dependentes[index].doc_verso})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                            </div>
                                            <br />
                                            <Input
                                                type="file"
                                                accept="image/*"
                                                name={`trasdocumento${index}`}
                                                id={`trasdocumento${index}`}
                                                onChange={e => FileToBase64Dependentes(e.target.files[0], 'tras', index)}
                                            />
                                        </Col>
                                    </Row>



                                </Col>
                                <Col lg="3"></Col>
                            </Row>
                            <br />
                            <hr />
                        </>
                    ))}
                    <br />

                    <Row>
                        <Col lg="12" style={{ textAlign: 'center' }}>
                            {spinner ? <Button className='btstep1' style={{ width: '200px' }} disabled><Spinner animation="border" variant="primary" /></Button> : <Button onClick={() =>
                                submitCadastros()
                                // {setStep(3)
                                // window.scrollTo(0, 0)}
                            } className='btstep1' style={{ width: '200px' }}>Continuar</Button>}
                        </Col>
                    </Row>
                    <br />
                    <br />
                </div>
                <div style={{ display: step === 3 ? 'block' : 'none' }}>
                    <Row>
                        <Col lg="12" style={{
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column'

                        }}>
                            <h4 style={{ textAlign: 'center', color: '#000' }}>Contrato de prestação de serviços:</h4>
                            <div
                                className='contrato'
                            >
                                <Contrato
                                    dadosUsuario={dadosUsuario}
                                    dependentes={dependentes}
                                    planoSelecionados={planoSelecionados}
                                    seguroSelecionado={seguroSelecionado}
                                    total={planoSelecionados.preco != null ? (planoSelecionados.preco + seguroSelecionado.precoSeguro + (planoSelecionados.preco_crianca * user1.length) + (planoSelecionados.preco_adulto * user2.length)).toFixed(2).replace('.', ',') : '0,00'}
                                    adicionais1={user1.length}
                                    adicionais2={user2.length}
                                />
                                {/* <iframe 
                    src="/contrato/contrato.html" 
                    title="Contrato"
                    style={{
                        width: '100%',
                        height: '200px',
                        border: 'none',
                        marginLeft: '-8.5rem'
                    }} 
                    /> */}
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col lg="12" style={{ textAlign: 'center' }}>
                            <span style={{ color: '#000', fontSize: '12px', fontWeight: 'bold' }}>Ao clicar em "Aceito", você concorda com os termos do contrato. </span> <Input type="checkbox" name="text" id="exampleText" checked={checado} onClick={() => setChecado(!checado)} />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col lg="12" style={{ textAlign: 'center' }}>
                            <Button onClick={() => {
                                handleExportPDF()
                                setStep(4)
                                window.scrollTo(0, 0)
                            }} className='btstep1' style={{ width: '200px' }} disabled={!checado}>Continuar</Button>
                        </Col>
                    </Row>
                    <br />
                    <br />

                </div>
                <div style={{ display: step === 4 ? 'block' : 'none', marginTop: '20px', marginBottom: '55vh' }}>

                    <Row>
                        <Col lg="3" ></Col>
                        <Col lg="6" style={{
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex'
                        }}>
                            <div style={{
                                width: '50%',
                            }}>
                                <Row>
                                    <Col lg="12">
                                        <h4 style={{ textAlign: 'center', color: '#000' }}>Pagamento:</h4>
                                    </Col>
                                </Row>
                                <p />
                                <h6 style={{ textAlign: 'center', color: 'gray' }}>Plano: {planoSelecionados.descricao}</h6>
                                <h6 style={{ textAlign: 'center', color: 'gray' }}>Total a pagar: R$ {planoSelecionados.preco != null ? (planoSelecionados.preco + seguroSelecionado.precoSeguro + (planoSelecionados.preco_crianca * user1.length) + (planoSelecionados.preco_adulto * user2.length)).toFixed(2).replace('.', ',') : '0,00'}</h6>
                                <p />
                                <Row>
                                    <Col lg="12">
                                        <div style={{ flexDirection: 'row', display: 'flex', width: '100%' }}>
                                            <Input onChange={(e) => handleInputChange(e.target.value)
                                            } type="text" name="text" id="numerocartao" placeholder="Número do Cartão" value={dadosCartao.numero} />
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'end',
                                                    position: 'relative',
                                                    right: '3rem',
                                                    height: '30px',
                                                    marginTop: '3px',

                                                }}
                                            >{bandeira(dadosCartao.numero)}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col lg="12">
                                        <Input onChange={(e) => handleInputValidateChange(e.target.value)} value={dadosCartao.validade} type="text" name="text" id="vvalidade" placeholder="MM/AA" />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col lg="12">
                                        <Input onChange={(e) => handleInputChangeCVV(e.target.value)} value={dadosCartao.cvv}
                                            type="number" name="text" id="cvv" placeholder="CVV" maxLength="3" />
                                    </Col>
                                </Row>

                            </div>
                        </Col>
                        <Col lg="3" ></Col>
                    </Row>
                    <br />
                    <Row>
                        <Col lg="12" style={{ textAlign: 'center' }}>
                            {spinner ? <Button className='btstep1' style={{ width: '200px' }} disabled><Spinner animation="border" variant="primary" /></Button> : <Button onClick={() => Pagar()} className='btstep1' style={{ width: '200px' }}>Pagar</Button>}
                        </Col>
                    </Row>
                    <br />
                    <br />

                </div>
                <div style={{ display: step === 5 ? 'block' : 'none' }}>
                    <Row>
                        <Col lg="3" ></Col>
                        <Col lg="6" style={{
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex'
                        }}>
                            <div style={{
                                width: '50%',
                            }}>
                                <Row>
                                    <Col lg="12">
                                        <h4 style={{ textAlign: 'center', color: '#000' }}>Resumo da Assinatura</h4>
                                    </Col>
                                </Row>
                                <p />
                                <h6 style={{ textAlign: 'center', color: 'gray' }}>Plano: {planoSelecionados.descricao}</h6>
                                <h6 style={{ textAlign: 'center', color: 'gray' }}>Valor mensal do contrato: R$ {planoSelecionados.preco != null ? (planoSelecionados.preco + seguroSelecionado.precoSeguro + (planoSelecionados.preco_crianca * user1.length) + (planoSelecionados.preco_adulto * user2.length)).toFixed(2).replace('.', ',') : '0,00'}</h6>
                                <br />
                                <span style={{ fontWeight: 'bold' }}>Titular:</span>
                                <span style={{ fontWeight: 'bold' }}> {dadosUsuario.primeiro_nome} {dadosUsuario.sobrenome}</span>
                                <br />
                                <br />
                                <span style={{ fontWeight: 'bold' }}>Dependentes:</span>
                                <br />
                                {dependentes.length > 0 ? <DependentesList /> : <>** Plano individual sem dependentes.</>}

                                <br />
                                <br />
                                <h5 style={{ textAlign: 'center', color: '#1990CF' }}>Para acessar sua conta, clique em "Área do Cliente" no menu superior.</h5>
                            </div>
                        </Col>
                    </Row>
                    <br />

                    <br />
                    <br />

                </div>


            </Row>

        </>
    )
}

export default Assinatura