const PaginationControls = ({ pagina, totalItems, limit, handleIrParaPrimeiraPagina, handleRetroceder, handleAvancar, handleIrParaUltimaPagina, handleChangeItensPorPagina }) => {
    return (
      <div className="pagination-controls">
        <button onClick={handleIrParaPrimeiraPagina} disabled={pagina === 1}>
          {"<<"} Primeira
        </button>
        <button onClick={handleRetroceder} disabled={pagina === 1}>
          {"<"} Anterior
        </button>
  
        <span>
          Página {pagina} de {Math.ceil(totalItems / limit)}
        </span>
  
        <button onClick={handleAvancar} disabled={pagina === Math.ceil(totalItems / limit)}>
          Próxima {">"}
        </button>
        <button onClick={handleIrParaUltimaPagina} disabled={pagina === Math.ceil(totalItems / limit)}>
          Última {">>"}
        </button>
  
        <select value={limit} onChange={handleChangeItensPorPagina}>
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
    );
  };
  
  export default PaginationControls;