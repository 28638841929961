/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Edit2, Filter, Link, Trash2 } from "react-feather";
import { Card, CardBody, Row, Col, Button, Label, Input } from "reactstrap";
import ModalUsuarios from "./modal";
import PaginationControls from "../../../components/paginador";
import {
  PaginadorDash,
  FindById,
  SaveCredenciado,
  RemoveCredenciado,
  UpdateCredenciado,
} from "../../../service/credenciados";
import { toast } from "react-toastify";
import SweetAlert2 from "react-sweetalert2";

const CredenciadosAdm = () => {
  const [swalProps, setSwalProps] = useState({});
  const [tipoModal, setTipoModal] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [pagina, setPagina] = useState(1);
  const [limit, setLimit] = useState(10)
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] =  useState(0);
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);
  const [idCredenciado, setIdCredenciado] = useState(null);
  const [formData, setFormData] = useState({
    nome: "",
    link_maps: "",
    link_instagram: "",
    link_facebook: "",
    ddd: "",
    telefone: "",
    endereco: "",
    bairro: "",
    cep: "",
    cidade_id: { value: "", label: "" },
    especialidades: [],
    imagem: undefined,
  });

  const [filter, setFilter] = useState([
    {
      nome: null,
      pagina: 1,    
      limite: 300,
    },
  ]);

  const [tooltipId, setTooltipId] = useState(null);

  const showTooltip = (id) => {
    setTooltipId(id);
  };

  const hideTooltip = () => {
    setTooltipId(null);
  };

  const handleOpenModal = (tipo, data) => {
    if (tipo === "Editar") {
      FindById(data)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            editCredenciados(response.data);
            setIdCredenciado(response.data.id);
            setTipoModal(tipo);
            return;
          }
          toast.error("Erro ao buscar Credenciado!");
        })
        .catch((err) => {
          console.log(err);
        });
    }

    setOpenModal(true);
  };

  const RemoverCredenciado = (id) => {
    RemoveCredenciado(id)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          toast.success("Credenciado removido com sucesso!");
          getCredenciados();
          return;
        }
        toast.error("Erro ao remover Credenciado!");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function TrashClick(id) {
    setSwalProps({
      show: true,
      title: "Deseja remover o Credenciado?",
      text: "Não será possível reverter esta ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, remover!",
      cancelButtonText: "Não, cancelar!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      reverseButtons: true,
      focusCancel: true,
      focusConfirm: false,

      preConfirm: () => {
        RemoverCredenciado(id);
        setSwalProps({ show: false });
      },
    });
  }

  const getExtension = (filename) => {
    const first = filename.charAt(0);
    switch (first) {
      case "/":
        return "jpg";
      case "i":
        return "png";
      case "R":
        return "gif";
      case "U":
        return "webp";
      case "Q":
        return "svg";
      default:
        return "jpg";
    }
  };

  const editCredenciados = (data) => {
    setFormData({
      nome: data.nome,
      link_maps: data.link_maps,
      link_instagram: data.link_instagram,
      link_facebook: data.link_facebook,
      ddd: data.ddd,
      telefone: data.telefone,
      endereco: data.endereco,
      bairro: data.bairro,
      cep: data.cep,
      cidade_id: { value: data.cidade_id, label: data.cidade },
      especialidades: data.especialidades,
      imagem: data.imagem,
    });
  };

  const onSubmit = () => {
    if (tipoModal === "Editar") {
      formData.id = idCredenciado;
    }
    formData.imagem =
      "data:image/" +
      getExtension(formData.imagem) +
      ";base64," +
      formData.imagem;
    formData.cidade_id = formData.cidade_id.value;
    formData.link_maps = `https://www.google.com/maps?q=${formData.endereco}, ${formData.bairro}, ${formData.cidade_id.label} - ${formData.cep}`;
    if (tipoModal === "Editar") {
      UpdateCredenciado(formData)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            toast.success("Credenciado editado com sucesso!");
            setOpenModal(false);
            getCredenciados();
            setFormData({
              nome: "",
              link_maps: "",
              link_instagram: "",
              link_facebook: "",
              ddd: "",
              telefone: "",
              endereco: "",
              bairro: "",
              cep: "",
              cidade_id: { value: "", label: "" },
              especialidades: [],
              imagem: undefined,
            });
            return;
          }
          toast.error("Erro ao editar Credenciado!");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      SaveCredenciado(formData)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            toast.success("Credenciado criado com sucesso!");
            setOpenModal(false);
            getCredenciados();
            setFormData({
              nome: "",
              link_maps: "",
              link_instagram: "",
              link_facebook: "",
              ddd: "",
              telefone: "",
              endereco: "",
              bairro: "",
              cep: "",
              cidade_id: { value: "", label: "" },
              especialidades: [],
              imagem: undefined,
            });
            return;
          }

          toast.error("Erro ao criar Credenciado!");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const columns = [
    {
      name: "Nome",
      selector: "nome",
      sortable: true,
      width: "50%",
    },
    {
      name: "Telefone",
      selector: "telefone",
      sortable: true,
      width: "20%",
    },
    {
      name: "Localidade",
      selector: "cidade",
      sortable: false,
      width: "15%",
    },
    {
      name: "Ações",
      selector: "acoes",
      sortable: true,
      right: true,
      cell: (row) => (
        <>
          <div
            onMouseOver={() => showTooltip("editar-" + row.id)}
            onMouseLeave={() => hideTooltip()}
            style={{
              position: "relative",
              display: "inline-block",
              cursor: "pointer",
            }}
          >
            <Edit2
              style={{ cursor: "pointer" }}
              size={18}
              onClick={() => handleOpenModal("Editar", row.id)}
            />
            {tooltipId === "editar-" + row.id && (
              <div className="tooltip-adm">Editar </div>
            )}
          </div>
          &nbsp;&nbsp;
          <div
            onMouseOver={() => showTooltip("remover-" + row.id)}
            onMouseLeave={() => hideTooltip()}
            style={{
              position: "relative",
              display: "inline-block",
              cursor: "pointer",
            }}
          >
            <Trash2
              style={{ cursor: "pointer" }}
              onClick={() => TrashClick(row.id)}
              size={18}
            ></Trash2>
            {tooltipId === "remover-" + row.id && (
              <div className="tooltip-adm">Remover</div>
            )}
          </div>
        </>
      ),
    },
  ];

  const getCredenciados = (paginaAtual = pagina, limiteAtual = limit) => {
    setLoading(true);    
    PaginadorDash({ pagina: paginaAtual, limite: limiteAtual, nome: filter[0].nome })
          .then((result) => {
            console.log("Resultado da consulta: ", result.data.data);
            setDados(result.data.data);
            setTotalItems(result.data.totalResults);
            setCurrentPage(result.data.currentPage); 
            setTotalPages(result.data.totalPages);
          })
          .catch((error) => {
            console.log(error);
            toast.error("Erro ao carregar os dados!");
          });
      };  


  useEffect(() => {
    getCredenciados(pagina, limit);
  }, [pagina, limit]);

  const handleAvancar = () => {
    setPagina((prevPagina) => {
      const novaPagina = prevPagina + 1;
      getCredenciados(novaPagina, limit);  
      return novaPagina;
    });
  };
  
  const handleRetroceder = () => {
    setPagina((prevPagina) => {
      const novaPagina = prevPagina > 1 ? prevPagina - 1 : prevPagina;
      getCredenciados(novaPagina, limit);
      return novaPagina;
    });
  };
  
  const handleIrParaPrimeiraPagina = () => {
    setPagina(1);
    getCredenciados(1, limit);
  };
  
  const handleIrParaUltimaPagina = () => {
    const ultimaPagina = Math.ceil(totalItems / limit);
    setPagina(ultimaPagina);
    getCredenciados(ultimaPagina, limit);
  };
  
  const handleChangeItensPorPagina = (e) => {
    const novoLimit = Number(e.target.value);
    setLimit(novoLimit);
    setPagina(1);
    getCredenciados(1, novoLimit);  
  };

  return (
    <>
      <ModalUsuarios
        tipoModal={tipoModal}
        openModal={openModal}
        setOpenModal={setOpenModal}
        formData={formData}
        setFormData={setFormData}
        onSubmit={onSubmit}
        setTipoModal={setTipoModal}
      />
      <SweetAlert2
        {...swalProps}
        didClose={() => setSwalProps({ show: false })}
      />
      <br />
      <Row>
        <Card>
          <CardBody>
            <Row>
              <Col sm="2">
                <h4>Credenciados</h4>
              </Col>
              <Col
                sm="10"
                style={{
                  justifyContent: "flex-end",
                  display: "flex",
                  alignItems: "right",
                }}
              >
                <Button
                  className="buttonSideFilter"
                  onClick={() => setOpenFilter(!openFilter)}
                >
                  <Filter size={18} />
                </Button>
                &nbsp;
                <Button
                  size="sm"
                  className="buttonSide"
                  onClick={() => handleOpenModal("Criar")}
                >
                  <Link size={18} />
                  &nbsp; Novo Credenciado
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
      <br />
      <div
        style={{
          display: openFilter ? "block" : "none",
          transitionTimingFunction: "ease-in-out",
          transitionDuration: "2s",
          transform: "translateY(-10px)",
        }}
      >
        <Row>
          <Card>
            <CardBody>
              <Row>
                <Col sm="1">
                  <h5>Filtros</h5>
                </Col>
                <Col sm="11">
                  <Row>
                    <Col sm="3">
                      <Label>Credenciado:</Label>
                      <Input 
                        type="text"
                        value={filter[0].credenciado}
                        onChange={(e) => {
                          setFilter([
                            {
                              ...filter[0],
                              nome: e.target.value,
                            },
                          ]);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Button
                        size="sm"
                        onClick={() => getCredenciados()}
                        className="buttonSide"
                        style={{ float: "right", width: "100px" }}
                      >
                        Filtrar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>
      </div>

      <Row>
        <Card>
          <CardBody>
            <Row>
              <DataTable
                columns={columns}
                data={dados}
                pagination={false}
                highlightOnHover
                responsive
                striped
              />
            </Row>
            <PaginationControls
              pagina={pagina}
              totalItems={totalItems}
              limit={limit}
              handleIrParaPrimeiraPagina={handleIrParaPrimeiraPagina}
              handleRetroceder={handleRetroceder}
              handleAvancar={handleAvancar}
              handleIrParaUltimaPagina={handleIrParaUltimaPagina}
              handleChangeItensPorPagina={handleChangeItensPorPagina}
            />
          </CardBody>
        </Card>
      </Row>
    </>
  );
};

export default CredenciadosAdm;
